<template>
  <div
    class="iframe-layout flex-row justify-center"
    :style="{ minHeight: minHeight + 'px' }"
  >
    <iframe class="iframe" :src="url"></iframe>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { LeftOutlined } from '@ant-design/icons-vue'
import { getCodePlatformUrl } from '@/api/config'

import { minheight } from '../components/sameVariable'

export default defineComponent({
  data () {
    return {
      codePlatformUrl: '',
      type: [0],
      spinning: false, // 分页部分
      myToDoItems: '#/officeDesk/processCenter/pendingManage/pendingItems',
      myInitiate: '#/officeDesk/processCenter/myStartPro/myRequest',
      myProcedure: '#/officeDesk/processCenter/myUndertakePro/handledMatters',
    }
  },
  created () {
    this.codePlatformUrl = getCodePlatformUrl()
    const query = this.$route.query
    this.minHeight = minheight
    const type = [this.$route.query.type]
    this.type = type
    const token = JSON.parse(localStorage.getItem('token'))
    var currentPage = ''
    switch (query.type) {
      case ('0'): {
        currentPage = this.myToDoItems
        break
      }
      case ('1'): {
        currentPage = this.myInitiate
        break
      }
      case ('2'): {
        currentPage = this.myProcedure
        break
      }
    }
    this.url = this.codePlatformUrl + currentPage + '?layout=none&access_token=' + token.access_token
    // this.url = codePlatformUrl + currentPage + '?layout=none&access_token=' + token.access_token
  },
  methods: {
    // 返回工作台
    backWorkBench () {
      this.$router.go(-1)
    },
    // 获取工作任务列表
    getWorkTaskList () { },
    handleOk (e) {
      this.visible = false
    },
  },
})
</script>

<style lang="scss" scoped>
@import "@/assets/common.scss";
.iframe-layout {
  text-align: center;
  width: 100%;

  .iframe {
    border: 0px none;
    height: 100%;
    width: 82%;
  }
  .ant-avatar {
    border-radius: 2px;
    width: 40px;
    height: 40px;
  }
}
</style>
